.backdrop {
  position: fixed; /* Set the position as fixed to prevent scrolling */
  top: 0; /* Position it at the top of the viewport */
  left: 0; /* Position it at the left of the viewport */
  width: 100%; /* Set the width to cover the entire viewport */
  height: 100vh; /* Set the height to cover the entire viewport height */
  background: rgba(
    1,
    0,
    0,
    0.75
  ); /* Set a semi-transparent red background color */
  z-index: 10; /* Set the z-index to control the stacking order */
}
