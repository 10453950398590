/* Style the footer */
.footer {
  background-color: var(--color-charcoal);
  color: white;
  text-align: center;
  padding: 2rem 0;
  width: 100%;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  border-top: 1px solid white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

/* Style footer elements */
.footer-element {
  margin: 1rem; /* Margin for each footer element */
}

.footer-element a {
  color: white; /* Anchor (link) color */
  text-decoration: none; /* Remove underline from links */
  font-weight: 500; /* Set the font weight */
}

/* Style the social links horizontally */
.social-links a {
  font-size: 2.2rem; /* Font size for social media icons */
}

/* Style the copyright text */
.copyright {
  font-size: 1.2rem; /* Font size for the copyright text */
  margin-top: 1rem; /* Top margin for the copyright element */
}

/* Media query for larger screens */
@media screen and (min-width: 549px) {
  .footer {
    font-size: 1.5rem; /* Adjusted font size for larger screens */
  }
  .copyright {
    font-size: 1.4rem; /* Adjusted font size for the copyright text on larger screens */
  }
}
