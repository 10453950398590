/* Define some custom CSS variables for easy reuse */
:root {
  --font-family-gilda: "Gilda Display", Georgia, "Times New Roman", serif; /* Define a font family for special use */
  --font-family-inter: "Inter", "Gilda Display", Arial, sans-serif; /* Define a fallback font family with Inter as a primary choice */
  --color-rustic-red: #9b4819; /* Define a custom color variable for rustic red */
  --color-charcoal: #292826; /* Define a custom color variable for charcoal */
  --scrollbar-width: 1rem; /* Define the scrollbar width */
  --scrollbar-thumb-color: var(
    --color-charcoal
  ); /* Define the scrollbar thumb color */
  --scrollbar-track-color: #f0f0f0; /* Define the scrollbar track color */
}

/* Apply a box-sizing reset to use 'border-box' by default for all elements */
* {
  box-sizing: border-box;
}

/* Set the base font size for easy rem-based calculations */
html {
  font-size: 62.5%; /* 1rem = 10px (10px / 16px = 62.5%) */
  scroll-padding-top: 5rem;
}

/* Apply a basic font and background color to the body */
body {
  width: 100vw;
  max-width: 120rem;
  margin: auto;
  font-family: var(
    --font-family-inter
  ); /* Use the Inter font family as the default */
  background-color: #ffffff; /* Set the background color of the body to white */
  padding: 0; /* Remove default padding */
  /* padding-top: 7rem; Add padding to body to avoid content overlapping with fixed header */
}

/* Reset some default styles */
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h2 {
  font-size: 2.6rem;
  font-weight: 400;
}

h2,
h3 {
  font-family: var(--font-family-gilda);
}

/* Customize the scrollbar */
body::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-bottom-left-radius: var(--scrollbar-width);
  border-bottom-right-radius: var(--scrollbar-width);
}

body::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

@media (min-width: 768px) {
  h2 {
    font-size: 3rem;
  }
}
