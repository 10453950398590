/* Styles for the hero section */
#hero {
  position: relative;
  padding: 0.5rem 0;
  text-align: center;
  padding-top: 0px;
}

/* Styles for the hello anime container */
.presentation-container {
  background-color: var(--color-charcoal);
  width: 100%;
  max-width: 100rem;
  padding-top: 130px;
  padding-bottom: 2px;
  margin: auto;
  margin-top: 61px;
  color: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

/* Styles for images within the hello anime container */
.presentation-container img {
  max-width: 94%;
  padding-right: 10px;
}

.tagline-container {
  margin-top: -25%;
  margin-bottom: 80px;
}

/* Styles for the tagline text */
.tagline {
  font-size: 18px;
  padding: 10px;
  padding-top: 3rem; /* Spacing above the tagline */
  font-weight: 600;
  line-height: 28px;
}

.consultation-info {
  position: relative;
  font-weight: 500;
  margin-bottom: -30px;
}

.consultation-info::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 30%;
  width: 40%;
  height: 2px;
  background-color: var(--color-rustic-red);
  transform: rotate(-2deg) skewX(-40deg);
}

/* Styles for the motto container */
.mottoContainer {
  max-width: 80rem;
  margin: auto;
  font-size: 1.4rem;
  margin-top: 8rem; /* Margin from the top of the motto container */
  padding: 0 1rem; /* Padding on the sides of the motto container */
}

/* Styles for images within the motto container */
.mottoContainer img {
  height: 8rem; /* Initial height of the image */
  transition: transform 0.2s ease-in-out; /* Add a smooth transition effect on hover */
}

/* Styles for image transformation on hover */
.mottoContainer img:hover {
  transform: scale(1.2); /* Scale the image to 120% on hover */
}

/* Media queries for responsive design */
@media screen and (min-width: 400px) {
  .tagline {
    font-size: 2rem;
  }

  .mottoContainer {
    font-size: 1.6rem;
  }

  .mottoContainer img {
    height: 9rem;
  }
}

@media screen and (min-width: 500px) {
  .tagline {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 600px) {
  .tagline {
    font-size: 2.4rem;
  }

  .mottoContainer {
    padding: 0 3rem;
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 740px) {
  .tagline {
    font-size: 2.5rem;
  }

  .mottoContainer img {
    height: 10rem;
  }
}

@media screen and (min-width: 900px) {
  .presentation-container {
    border-radius: 12px;
  }

  .tagline {
    font-size: 3.2rem;
  }

  .consultation-info {
    margin-top: 60px;
  }

  .mottoContainer {
    font-size: 2rem;
    letter-spacing: 0.5px;
  }

  .mottoContainer img {
    height: 11rem;
  }
}
